import React from 'react'
import { Space, Layout } from 'antd'

import { useWindowSize } from '../hooks'
import './App.css'
import logo from '../images/logo.png'
import trophy from '../images/trophy.svg'

const { Content, Footer } = Layout

function Loading({ error }) {
  const { height } = useWindowSize()

  return (
    <div className="logo-container">
      <img alt="Loading" className="trophy-spin" src={trophy} />
    </div>
  )
}

export default Loading