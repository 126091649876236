import { split, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/link-ws'

import resolvers from './resolvers'
import typeDefs from './typeDefs'

export function createClient(headers) {
  const cache = new InMemoryCache({
    freezeResults: true,
  })

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API,
    headers
  })

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_API,
    options: {
      reconnect: true,
      connectionParams: {
        headers
      }
    }
  })

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink,
  )

  const client = new ApolloClient({
      link: splitLink,
      cache,
      resolvers,
      typeDefs,
      assumeImmutableResults: true
  })

  return client
}
