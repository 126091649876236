
import gql from 'graphql-tag'

const typeDefs = gql `
    type Message {
        id: Int!
        value: String!,
        user_displayName: String!,
        user_photoURL: String!,
        project_id: Int!,
        created_at: Timestamp,
        updated_at: Timestamp,
    }
    type Project {
        id: Int!
        name: String!
        family: String!
      }      
`

export default typeDefs
