import React, { useState, useEffect, useMemo } from "react"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/storage"

import { ApolloProvider, useQuery, useMutation } from '@apollo/client'

import AppLoading from './AppLoading'
import AppLogin from "./AppLogin"
import App from "./App"
import { createClient } from '../apollo'

import { GET_USER } from '../apollo/queries'
import { ADD_USER } from '../apollo/mutations'

const googleProvider = new firebase.auth.GoogleAuthProvider()
const yahooProvider = new firebase.auth.OAuthProvider('yahoo.com')

// Find these options in your Firebase console
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

export const UserContext = React.createContext({ user: {} })

function AppUser({ authState, signOut }) {
  console.log("AUTHSTATE: ", authState)
  const { loading, error, data } = useQuery(GET_USER, { variables: { id: authState.user.uid } })
  const [addUser] = useMutation(ADD_USER, {
    update(cache, { data: { insert_user_one } }) {
      cache.writeQuery({
        query: GET_USER,
        variables: { id: insert_user_one.id },
        data: { user: insert_user_one },
      })
    }
  })

  console.log("Data: ", data, error)

  useEffect(() => {
    if (data && !data.user) {
      addUser({ variables: { id: authState.user.uid, email: authState.user.email, displayName: authState.user.displayName, photoURL: authState.user.photoURL }})
    }
  }, [data])

  if (loading || error || !data || !data.user) return <AppLoading />

  const { user } = data
  return (
    <UserContext.Provider value={{ user, authState }}>
      <App user={user} signOut={signOut} />
    </UserContext.Provider>
  )
}

export default function Auth() {
  const [authState, setAuthState] = useState({ status: "loading" })

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdToken()
        const idTokenResult = await user.getIdTokenResult()
        const hasuraClaim = idTokenResult.claims && idTokenResult.claims["https://hasura.io/jwt/claims"]
        const adminClaim = idTokenResult.claims && idTokenResult.claims["admin"]

        if (hasuraClaim && adminClaim) {
          // For Yahoo
          // setAuthState({ status: "in", user: user.providerData[0], token, admin: true }) 
          setAuthState({ status: "in", user: user, token, admin: true })   
        } else if (hasuraClaim) {
          setAuthState({ status: "in", user: user, token })   
        } else {
          // Check if refresh is required.
          const metadataRef = firebase
            .database()
            .ref("metadata/" + user.uid + "/refreshTime");

          metadataRef.on("value", async (data) => {
            if(!data.exists) return
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true)
            setAuthState({ status: "in", user, token })
          })
        }
      } else {
        setAuthState({ status: "out" })
      }
    })
  }, [])

  const signInWithGoogle = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(googleProvider)
      const { user } = result
      setAuthState({ ...authState, user })
    } catch (error) {
      console.log(error)
    }
  }

  // const signInWithYahoo = async () => {
  //   try {
  //     const result = await firebase.auth().signInWithPopup(yahooProvider)
  //     const { user } = result
  //     setAuthState({ ...authState, user: user.providerData[0] })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const signOut = async () => {
    try {
      setAuthState({ status: "loading" })
      await firebase.auth().signOut()
      setAuthState({ status: "out" })
    } catch (error) {
      console.log(error)
    }
  }

  const headers = authState.status === "in" ? { Authorization: `Bearer ${authState.token}` } : {};
  return (
    <ApolloProvider client={createClient(headers)}>
      {authState.status === "loading" && <AppLoading />}
      {authState.status === "in" && authState.user && <AppUser signOut={signOut} authState={authState} />}
      {authState.status === "out" && <AppLogin signIn={signInWithGoogle} />}
    </ApolloProvider>
  ) 
}