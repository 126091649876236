import { gql } from '@apollo/client'

export const ADD_USER = gql`
  mutation ($id: String!, $displayName: String!, $email: String!, $photoURL: String!) {
    insert_user_one(
      object: {
          id: $id,
          displayName: $displayName,
          email: $email,
          photoURL: $photoURL,
      }
    ) {
      id
      displayName
      email
      photoURL
    }
  }
`

export const UPDATE_USER = gql`
  mutation ($id: String!, $displayName: String!, $photoURL: String) {
    update_user_by_pk(pk_columns: {id: $id}, _set: { displayName: $displayName, photoURL: $photoURL }) {
      id
      displayName
      email
      photoURL
    }
  }
`

export const EMIT_ONLINE_MEMBER = gql`
  mutation ($id: Int!, $is_online: Boolean!, $in_chat: Boolean!, $in_video: Boolean!){
    update_league_member_by_pk(pk_columns: {id: $id}, _set: { is_online: $is_online, in_chat: $in_chat, in_video: $in_video }) {
      id
      role
      is_online
      in_chat
      in_video
      user {
        id
        displayName
        email
        photoURL  
      }
    }
  }
`

export const ADD_LEAGUE_MEMBER = gql`
  mutation AddLeagueMember($user_id: String!, $league_id: Int!, $role: String!) {
    insert_league_member_one(
      object: {
        user_id: $user_id,
        league_id: $league_id,
        role: $role,
      }
    ) {
      id
      role
      user {
        id
        photoURL
        email
        displayName
      }
    }
    insert_unread_message_one (
      object: {
        league_id: $league_id,
        user_id: $user_id,
      }
    ) {
      id
    }
  }
`

export const UPDATE_LEAGUE_MEMBER = gql`
  mutation UpdateLeagueMember($id: Int!, $role: String!) {
    update_league_member_by_pk(pk_columns: {id: $id},
      _set: {
        role: $role,
      }
    ) {
      id
      role
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const REMOVE_LEAGUE_MEMBER = gql`
  mutation RemoveLeagueMember($id: Int!) {
    delete_league_member_by_pk(id: $id) {
      id
    }
  }
`

export const ADD_LEAGUE = gql`
  mutation AddLeague($name: String!, $slogan: String,  $chatroom: String, $owner_id: String!) {
    insert_league_one(
      object: {
          name: $name,
          slogan: $slogan,
          owner_id: $owner_id,
          chatroom: $chatroom
      }
    ) {
      id
      name
      slogan
      owner_id
      avatarURL
    }
  }
`

export const UPDATE_LEAGUE = gql`
  mutation UpdateLeague($id: Int!, $name: String!, $slogan: String, $chatroom: String, $password: String, $avatarURL: String) {
    update_league_by_pk(pk_columns: {id: $id},
      _set: {
          name: $name,
          slogan: $slogan,
          chatroom: $chatroom,
          password: $password,
          avatarURL: $avatarURL,
      }
    ) {
      id
      name
      slogan
      owner_id
      chatroom
      password
      avatarURL
    }
  }
`

// IDEAS
export const ADD_IDEA = gql`
  mutation AddIdea($league_id: Int!, $user_id: String!, $value: String!, $poll: Boolean, $options: jsonb) {
    insert_idea_one(
      object: {
          league_id: $league_id,
          user_id: $user_id,
          value: $value,
          poll: $poll,
          options: $options,
      }
    ) {
      id
      value
      approved
      passed
      poll
      options
      league_id
      user_id
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const ADD_VOTE = gql`
  mutation AddIdeaVote($idea_id: Int!, $user_id: String!, $approve: Boolean, $option: Int) {
    insert_vote_one(
      object: {
          idea_id: $idea_id,
          user_id: $user_id,
          approve: $approve,
          option: $option,
      }
    ) {
      id
      idea_id
      user_id
      approve
      option
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const UPDATE_IDEA = gql`
  mutation UpdateVote($id: Int!, $value: String, $passed: Boolean, $poll: Boolean, $options: jsonb) {
    update_idea_by_pk(pk_columns: {id: $id}, _set: { value: $value, passed: $passed, poll: $poll, options: $options }) {
      id
      value
      approved
      passed
      poll
      options
      league_id
      user_id
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const UPDATE_VOTE = gql`
  mutation UpdateVote($id: Int!, $approve: Boolean, $option: Int) {
    update_vote_by_pk(pk_columns: {id: $id}, _set: { approve: $approve, option: $option }) {
      id
      idea_id
      user_id
      approve
      option
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`


export const REMOVE_IDEA = gql`
  mutation RemoveVote($id: Int!) {
    delete_idea_by_pk(id: $id) {
      id
    }
  }
`

// EVENTS
export const ADD_EVENT = gql`
  mutation AddEvent($league_id: Int!, $user_id: String!, $value: String!, $location: String, $date: String, $time: String) {
    insert_event_one(
      object: {
          league_id: $league_id,
          user_id: $user_id,
          value: $value,
          location: $location,
          date: $date,
          time: $time,
      }
    ) {
      id
      value
      location
      date
      time
      user_id
      attendees {
        id
        user_id
        event_id
        attending
        user {
          id
          photoURL
          email
          displayName
        }  
      }
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const ADD_ATTENDEE = gql`
  mutation AddEventAttendee($event_id: Int!, $user_id: String!, $attending: Boolean!) {
    insert_attendee_one(
      object: {
          event_id: $event_id,
          user_id: $user_id,
          attending: $attending,
      }
    ) {
      id
      event_id
      user_id
      attending
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($id: Int!, $value: String, $location: String, $date: String, $time: String) {
    update_event_by_pk(pk_columns: {id: $id}, _set: { value: $value, location: $location, date: $date, time: $time }) {
      id
      value
      location
      date
      time
      user_id
      attendees {
        id
        event_id
        user_id
        attending
        user {
          id
          photoURL
          email
          displayName
        }  
      }
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const UPDATE_ATTENDEE = gql`
  mutation UpdateAttendee($id: Int!, $attending: Boolean!) {
    update_attendee_by_pk(pk_columns: {id: $id}, _set: { attending: $attending }) {
      id
      event_id
      user_id
      attending
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`


export const REMOVE_EVENT = gql`
  mutation RemoveEvent($id: Int!) {
    delete_event_by_pk(id: $id) {
      id
    }
  }
`
// BETS
export const ADD_BET = gql`
  mutation AddBet($league_id: Int!, $bettor_id: String!, $challenger_id: String!, $amount: String!, $value: String!) {
    insert_bet_one(
      object: {
          league_id: $league_id,
          bettor_id: $bettor_id,
          challenger_id: $challenger_id,
          amount: $amount,
          value: $value,
      }
    ) {
      id
      amount
      value
      accepted
      rejected
      winner_id
      bettor {
        id
        photoURL
        email
        displayName
      }
      challenger {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const UPDATE_BET = gql`
  mutation UpdateBet($id: Int!, $accepted: Boolean!, $rejected: Boolean!, $winner_id: String) {
    update_bet_by_pk(pk_columns: {id: $id}, _set: { accepted: $accepted, rejected: $rejected, winner_id: $winner_id }) {
      id
      amount
      value
      accepted
      rejected
      winner_id
      bettor {
        id
        photoURL
        email
        displayName
      }
      challenger {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const REMOVE_BET = gql`
  mutation RemoveBet($id: Int!) {
    delete_bet_by_pk(id: $id) {
      id
    }
  }
`


// DUES
export const ADD_DUE = gql`
  mutation AddDue($league_id: Int!, $payee_id: String!, $payer_id: String!, $amount: String!, $value: String!) {
    insert_due_one(
      object: {
          league_id: $league_id,
          payee_id: $payee_id,
          payer_id: $payer_id,
          amount: $amount,
          value: $value,
      }
    ) {
      id
      amount
      value
      paid
      payee {
        id
        photoURL
        email
        displayName
      }
      payer {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const UPDATE_DUE = gql`
  mutation UpdateDue($id: Int!, $paid: Boolean!) {
    update_due_by_pk(pk_columns: {id: $id}, _set: { paid: $paid }) {
      id
      amount
      value
      paid
      payee {
        id
        photoURL
        email
        displayName
      }
      payer {
        id
        photoURL
        email
        displayName
      }
    }
  }
`

export const REMOVE_DUE = gql`
  mutation RemoveDue($id: Int!) {
    delete_due_by_pk(id: $id) {
      id
    }
  }
`



export const UPDATE_UNREAD_MESSAGE = gql`
  mutation UpdateUnreadMessage($league_id: Int!, $user_id: String!, $last_message_count: Int!) {
    update_unread_message(
      where: {league_id: { _eq: $league_id }, user_id: { _eq: $user_id }},
      _set: {
          last_message_count: $last_message_count
      }
    ) {
      affected_rows
      returning {
        id
        last_message_count
      }
    }
  }
`

export const ADD_MESSAGE = gql`
  mutation AddMessage($value: String!, $league_id: Int!, $user_id: String!){
    addMessage: insert_message_one (
      object: {
        value: $value,
        league_id: $league_id,
        user_id: $user_id,
      }
    ) {
      id
      created_at
      value
      user_id
      league_id
      user {
        displayName
        photoURL
      }
    }
  }
`

export const REMOVE_MESSAGE = gql`
  mutation RemoveMessage($id: Int!) {
    delete_message_by_pk(id: $id) {
      id
    }
  }
`