import React, { useState, useMemo } from 'react'
import { Modal, Avatar, Button, Row, Col, Card, Tag, Popconfirm, Tabs } from 'antd'
import { Form, Input } from 'formik-antd'
import { PlusOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'

import { Formik } from 'formik'
import { GET_LEAGUE_DETAILS } from '../apollo/queries'
import { ADD_BET, UPDATE_BET, REMOVE_BET } from '../apollo/mutations'
import handshake from '../images/handshake-2.svg'
import NoData from './NoData'

const Bets = ({ league, user, member }) => {
  const { bets, league_members } = league
  const [state, setState] = useState({ visible: false, amount: '', value: '', challenger_id: '' })
  const [addBet] = useMutation(ADD_BET, {
    update(cache, { data: { insert_bet_one } }) {
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, bets: [insert_bet_one, ...league.bets] } },
      })
    }
  })

  const [editBet] = useMutation(UPDATE_BET, {
    update(cache, { data: { update_bet_by_pk } }) {
      const newBets = league.bets.map(b => {
        if (b.id === update_bet_by_pk.id) return update_bet_by_pk
        return b
      })
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { id: league.id, user_id: user.id },
        data: { league: { ...league, bets: [...newBets] } },
      })
    }
  })


  const [deleteBet] = useMutation(REMOVE_BET, {
    update(cache, { data: { delete_bet_by_pk } }) {
      const newBets = league.bets.filter(i => i.id !== delete_bet_by_pk.id)
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { id: league.id, user_id: user.id },
        data: { league: { ...league, bets: [...newBets] } },
      })
    }
  })

  const handleSubmit = async (values) => {
    if (state.challenger_id) {
      addBet({ variables: { ...values, league_id: league.id, challenger_id: state.challenger_id, bettor_id: user.id } })
      setState({ visible: false, challenger_id: '',  amount: '', value: ''  })
    }
  }

  const handleAccept = id => {
    editBet({ variables: { id, accepted: true, rejected: false, winner_id: '' }})
  }

  const handleReject = id => {
    editBet({ variables: { id, accepted: false, rejected: true, winner_id: '' }})
  }

  const handleWinner = (id, winner_id) => {
    editBet({ variables: { id, accepted: true, rejected: false, winner_id }})
  }

  const handleReset = (id) => {
    editBet({ variables: { id, accepted: true, rejected: false, winner_id: '' }})
  }

  const handleCancel = () => {
    setState({ visible: false, amount: '', value: '', challenger_id: '' })
  }

  const handleDelete = (id) => {
    deleteBet({ variables: { id } })
  }

  const setChallenger = id => {
    setState({ ...state, challenger_id: id })
  }

  const Description = ({ bet }) => {
    const inBet = bet.accepted && (user.id === bet.bettor.id || user.id === bet.challenger.id)
    return (
      <div className="due-container">
        <div className="bet-info" style={inBet ? { cursor: 'pointer'} : {}}>
          <div className={bet.winner_id === bet.bettor.id ? 'winner in-bet' : 'in-bet'} onClick={() => inBet ? handleWinner(bet.id, bet.winner_id === bet.bettor.id ? '' : bet.bettor.id) : {}}>
            <Avatar size={64} src={bet.bettor.photoURL} />
          </div>
          <p>{bet.bettor.displayName}</p>
        </div>
        <div className="bet-info" style={inBet ? { cursor: 'pointer'} : {}}>
          <div className={bet.winner_id === bet.challenger.id ? 'winner in-bet' : 'in-bet'} onClick={() => inBet ? handleWinner(bet.id, bet.winner_id === bet.challenger.id ? '': bet.challenger.id) : {}}><Avatar size={64} src={bet.challenger.photoURL} /></div>
          <p>{bet.challenger.displayName}</p>
        </div>
        <div className="bet-amount">
          {!bet.accepted && !bet.rejected && bet.challenger.id === user.id && <Popconfirm
              title="Accept challenge?"
              onConfirm={() => handleAccept(bet.id)}
              onCancel={() => handleReject(bet.id)}
              okText="Accept"
              cancelText="Reject"
            >
            <Button size="large" shape="circle" type="primary" icon={<img src={handshake} />} /> 
          </Popconfirm>}
          {(bet.accepted || bet.rejected || bet.challenger.id !== user.id) &&
            <Button size="large" disabled shape="circle">VS
          </Button>}
        </div>
        <div><strong>{bet.amount}</strong><i></i></div>
      </div>
    )
  }

  const Footer = () => (
    <div className="add-button"><Button type="primary" size="large" shape="circle" onClick={() => setState({ ...state, visible: true })}><PlusOutlined /></Button></div>
  )

  const initialValues = { amount: state.amount, value: state.value }
  const canEdit = b => member.role === 'manager' || league.owner_id === user.id || b.bettor.id === user.id
  
  const filteredBets = useMemo(() => {
    return { open: bets.filter(b => !b.accepted && !b.rejected), accepted: bets.filter(b => b.accepted), rejected: bets.filter(b => b.rejected) }
  }, [bets])

  return (
    <div>
      <Tabs tabPosition="top">
        <Tabs.TabPane tab="Open" key="Submitted">
          <Row gutter={[16, 16]}>
            {!filteredBets.open.length && <NoData />}
            {filteredBets.open.map(b => (
              <Col key={b.id} xs={24} md={12} xl={12}>
                <Card
                  title={
                  <>
                    <p>{b.value}</p>
                    {canEdit(b) && <div className="actions">
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => handleDelete(b.id)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                      <a href="#">Remove</a>
                    </Popconfirm>
                  </div>}
                  </>}>
                  <Description bet={b} />
                </Card>
              </Col>   
            ))}
            </Row>
          <Footer />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Accepted" key="accepted">
          <Row gutter={[16, 16]}>
          {!filteredBets.accepted.length && <NoData />}
          {filteredBets.accepted.map(b => (
              <Col key={b.id} xs={24} md={12} xl={12}>
                <Card
                  title={
                  <>
                    <p>{b.value}</p>
                    {canEdit(b) && <div className="actions">
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => handleDelete(b.id)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                      <a href="#">Remove</a>
                    </Popconfirm>
                  </div>}
                  </>}>
                  <Description bet={b} />
                </Card>
              </Col>   
            ))}
          </Row>          
        </Tabs.TabPane>
      </Tabs>
      <Formik initialValues={initialValues} enableReinitialize>
        {(props) => (
            <Modal
              title="Add Challenge"
              visible={state.visible}
              onOk={() => handleSubmit(props.values)}
              onCancel={handleCancel}
            >
              <Form>
                <Input style={{ margin: '10px 0' }} size="large" name="value" placeholder="Challenge" />
                <Input style={{ margin: '10px 0' }} size="large" name="amount" placeholder="Stakes" />
                <div style={{ margin: '10px 0' }}>
                  <small>Challenger</small>
                  {league_members.filter(m => m.id !== member.id).map(m => <div className={state.challenger_id === m.user.id ? 'payer-avatar paying' : 'payer-avatar'} onClick={() => setChallenger(m.user.id)}><Avatar src={m.user.photoURL} /></div>)}
                </div>
              </Form> 
            </Modal>
          )
        }
      </Formik>
    </div>
  )
}

export default Bets
