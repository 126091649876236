import React, { useState, useMemo } from 'react'
import firebase from "firebase/app"
import "firebase/database"
import { Avatar, Badge, Button, Row, Col, Card, Tag, AutoComplete, Select, Modal, Popconfirm, Statistic } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import FileUploader from "react-firebase-file-uploader"
import leagues from '../images/leagues.svg'

import Loading from './Loading'

import { Form, Input } from 'formik-antd'
import { Formik } from 'formik'

import { SEARCH_USERS, GET_LEAGUE_DETAILS } from '../apollo/queries'
import { UPDATE_LEAGUE, ADD_LEAGUE_MEMBER, UPDATE_LEAGUE_MEMBER, REMOVE_LEAGUE_MEMBER } from '../apollo/mutations'

import { useDebounce } from '../hooks'

const roleOptions = [
  { value: 'manager', label: 'Manager' },
  { value: 'member', label: 'Member' },
  { value: 'guest', label: 'Guest' },
]

const description = {
  manager: 'A league manager has full access to all features and can edit league info, add and remove members, approve and pass voting motions and remove challenges. Same access as a owner.',
  member: 'A league member can participate in all features and remove any motions, challenges, events or messages that they create.',
  guest: 'A league guest has limited access to participate in the league and can be removed .'
}

const AddMember = ({ league, members }) => {
  const [query, setQuery] = useState('')
  const [role, setRole] = useState('member')
  const [userId, setUserId] = useState()

  const debouncedQuery = useDebounce(query, 350)
  const ilikeQuery = `%${debouncedQuery}%`
  const memberIds = useMemo(() => members.map(m => m.user.id), [members])

  const [addLeagueMember] = useMutation(ADD_LEAGUE_MEMBER)

  const { error, data } = useQuery(SEARCH_USERS, { variables: { searchText: ilikeQuery }, limit: 10 })

  const options = data ? data.users.filter(u => !memberIds.includes(u.id)) : []

  const selectedUser = useMemo(() => {
    if (userId && data) {
      return data.users.find(u => u.id === userId)
    }
    return null
  }, [userId])

  const handleAdd = () => {
    addLeagueMember({ variables: { user_id: userId, league_id: league.id, role } })
    setUserId()
    setRole('member')
    setQuery('')
  }

  const handleSelect = id => {
    setUserId(id)
  }

  const handleRoleChange = value => {
    setRole(value)
  }

  return (
    <Card className="league-member" title="Add Member">
      {!selectedUser && <AutoComplete
        style={{ maxWidth: 300, display: 'block' }}
        onSearch={query => setQuery(query)}
        onSelect={handleSelect}
        placeholder="Search users"
      >
        {options.length > 0 && options.map(option => (
          <AutoComplete.Option key={option.id} value={option.id}>
            <div>
              <Avatar src={option.photoURL} /> {option.displayName}<br />
              <small>{option.email}</small>
            </div>
          </AutoComplete.Option>
        ))}
        {debouncedQuery.length > 0 && options.length === 0 && 
          <AutoComplete.Option key='no-results' value={''}>
            No results
          </AutoComplete.Option>
        }
        </AutoComplete>}
        {selectedUser && <div><Avatar src={selectedUser.photoURL} /> {selectedUser.displayName} <Button style={{ float: 'right' }} onClick={() => setUserId()}>Clear</Button></div>}
        <Select
          style={{ maxWidth: 300, marginTop: 20, marginBottom: 20, display: 'block' }}
          defaultValue="member"
          value={role}
          onChange={handleRoleChange}
        >
        {roleOptions.map(option => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
      <div>{description[role]}</div>
      <Button type="primary" style={{ float: 'right', marginTop: '10px' }} disabled={!userId} onClick={handleAdd}>Add</Button>
    </Card>
  )
}

const UserDescription = ({ league, member, members, canEdit }) => {
  const [editLeagueMember] = useMutation(UPDATE_LEAGUE_MEMBER)
  const [removeLeagueMember] = useMutation(REMOVE_LEAGUE_MEMBER, {
    update(cache, { data: { delete_league_member_by_pk } }) {
      const newMembers = members.filter(m => m.id !== delete_league_member_by_pk.id)
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { id: league.id },
        data: { league: { ...league, league_members: [...newMembers]} },
      })
    }
  })

  const handleRoleUpdate = option => {
    editLeagueMember({ variables: { id: member.id, role: option.value } })
  }

  const handleRemove = id => {
    removeLeagueMember({ variables: { id } })
  }

  return (
    <div style={{ margin: '20px' }}>
      {member.user.id === league.owner_id && <Tag color="#1890ff">OWNER</Tag>}
      {!canEdit && member.user.id !== league.owner_id && <Tag color="#1890ff">{member.role.toUpperCase()}</Tag>}
      {canEdit && member.user.id !== league.owner_id && roleOptions.map(option => (
          <Tag.CheckableTag 
            key={option.value} 
            checked={member.role === option.value}
            onChange={() => canEdit ? handleRoleUpdate(option) : {}}
            value={option.value}>
            {option.value.toUpperCase()}
          </Tag.CheckableTag>
        ))}
      {member.user.id !== league.owner_id && canEdit &&
        <Popconfirm
          title="Are you sure?"
          onConfirm={() => handleRemove(member.id)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <a href="#" className="remove-member">Remove</a>
        </Popconfirm>}
    </div>
  )
}

const Members = ({ league, user, straw, loading, error, data, canEdit }) => {
  if (loading || error || !data) return <Loading error={error} />
  const { members } = data

  const isOnline = member => {
    if (member.user.id === user.id) return true
    return member.is_online
  }

  return (
    <div style={{ marginTop: 50 }}>
      <h3>Members</h3>
      <Row gutter={[16, 16]}>
        {members.map((m, i) => (
          <Col key={m.user.id} xs={24} lg={12}>
            <Card className={straw === i ? `league-member straw-member` : `league-member`} title={<span><Badge status={isOnline(m) ? "success" : "error"} style={{ margin: '0 5px' }} />{m.user.displayName}</span>}>
                <Card.Meta
                  avatar={<Avatar size={64} src={m.user.photoURL} />}
                  description={<UserDescription canEdit={canEdit} member={m} members={members} league={league} />}
                />
              </Card>
          </Col>   
        ))}
        {canEdit && <Col xs={24} lg={12}><AddMember league={league} members={members} /></Col>}
      </Row>
    </div>
  )
}

const Overview = ({ league, member, user, loading, error, data }) => {
  const [state, setState] = useState({ visible: false, isUploading: false, progress: 0, avatar: '', avatarURL: '', straw: null })

  const handleUploadStart = () => setState({ ...state, isUploading: true, progress: 0 })
  const handleProgress = progress => setState({ ...state, progress })
  const handleUploadError = error => {
    setState({ ...state, isUploading: false })
    console.error(error);
  }

  const handleUploadSuccess = filename => {
    setState({ ...state, avatar: filename, progress: 100, isUploading: false })

    firebase
      .storage()
      .ref("league-images")
      .child(filename)
      .getDownloadURL()
      .then(url => {
        setState({ ...state, avatarURL: url })
      })
  }

  const [updateLeague] = useMutation(UPDATE_LEAGUE, {
    update(cache, { data: { update_league_by_pk } }) {
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, ...update_league_by_pk } },
      })
    }
  })

  const handleUpdate = async (values) => {
    const { name, slogan, chatroom, password } = values
    updateLeague({ variables: { id: league.id, name, slogan, chatroom, password, avatarURL: state.avatarURL || league.avatarURL } })
    setState({ visible: false, isUploading: false, progress: 0, avatar: '', avatarURL: '' })
  }

  const drawStraws = () => {
    if (data) {
      const random = Math.floor(Math.random() * data.members.length)
      setState({ ...state, straw: random })  
    }
  }

  const canEdit = useMemo(() => {
    return  league.owner_id === user.id || member.role === 'manager'
  }, [league, member])

  const leagueCount = useMemo(() => {
    let members = 0
    let guests = 0
    if (data) {
      members = data.members.filter(m => m.role !== 'guest').length
      guests = data.members.filter(m => m.role === 'guest').length  
    }
    return { members, guests }
  }, [data])


  const initialValues = useMemo(() => {
    const { name, slogan, chatroom, password, avatarURL } = league
    return { name, slogan, chatroom, password, avatarURL }
  }, [league])

  return (
    <div>
      <Card title={<h3>{league.name}</h3>} extra={canEdit && <a href="#" onClick={() => setState({ ...state, visible: true })}>Edit</a>}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <div className="league-info"><Avatar src={league.avatarURL} size={120} /><small>{league.slogan}</small></div>
          </Col>
          <Col xs={24} lg={12}>
            <Row gutter={16} style={{ textAlign: 'center' }}>
              <Col span={8}>
                <Statistic title="Members" value={leagueCount.members} prefix={<imr src={leagues} />}  />
              </Col>
              <Col span={8}>
                <Statistic title="Guests" value={leagueCount.guests} prefix={<imr src={leagues} />}  />
              </Col>
              <Col span={8}>
                <Button className="straw-button" onClick={drawStraws}>Draw Straws</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Formik initialValues={initialValues} enableReinitialize>
      {(props) => (
          <Modal
            title="Edit League"
            visible={state.visible}
            onOk={() => handleUpdate(props.values)}
            onCancel={() => setState({ ...state, visible: false })}
          >
            <Form>
              <Input style={{ margin: '10px 0' }} size="large" name="name" placeholder="Name" />
              <Input style={{ margin: '10px 0' }} size="large" name="slogan" placeholder="Slogan" />
              <Input style={{ margin: '10px 0' }} size="large" name="chatroom" placeholder="Video Chatroom Name" />
              <Input style={{ margin: '10px 0 20px 0' }} size="large" name="password" placeholder="Video Chatroom Password" />
              <label className="file-upload-button">Upload League Avatar
              <FileUploader
                hidden
                accept="image/*"
                name="avatar"
                randomizeFilename
                storageRef={firebase.storage().ref("league-images")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSuccess}
                onProgress={handleProgress}
              />
              </label>
              {state.avatarURL && <Avatar src={state.avatarURL} />}
            </Form> 
          </Modal>
        )}
      </Formik>
      <Members league={league} user={user} straw={state.straw} loading={loading} error={error} data={data} canEdit={canEdit} />
    </div>
  )
}

export default Overview
