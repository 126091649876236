import React from 'react'
import { Layout, Row, Col } from 'antd'

import { useWindowSize } from '../hooks'
import './App.css'
import logo from '../images/logo.png'
import trophy from '../images/trophy.svg'

const { Content, Footer } = Layout

function AppLoading({ error }) {
  return (
    <Layout>
      <Content style={{ margin: '24px 16px 0', minHeight: '100vh' }}>
        <Row gutter={[32, 32]} className="login-preview-content">
            <Col xs={24} xl={24} >
              <div className="splash">
                <div><img alt="Logo" className="logo" src={logo} /></div>
                <div className="splash-content">
                  <img alt="trophy" className="trophy-spin" src={trophy} />
                </div>
              </div>
            </Col>
          </Row>
      </Content>
      <Footer className="footer">Tide Web Services ©2020</Footer>
    </Layout>
  )
}

export default AppLoading