import React, { useState, useMemo, useEffect } from 'react'
import { useQuery, useMutation, useSubscription } from '@apollo/client'

import vote from '../images/vote.svg'
import mobilePay from '../images/mobile-pay.svg'
import textChat from '../images/text-chat.svg'
import videoChat from '../images/video-chat.svg'
import trophy from '../images/trophy-black.svg'
import handshake from '../images/handshake.svg'
import event from '../images/event.svg'
import peace from '../images/peace.svg'
import back from '../images/back.svg'

import AppLayout from './AppLayout'
import AppLoading from './AppLoading'
import Overview from './Overview'
import Bets from './Bets'
import Votes from './Votes'
import Events from './Events'
import Dues from './Dues'
import Chat from './Chat'
import VideoChat from './VideoChat'

import { GET_LEAGUE_DETAILS, GET_MESSAGE_COUNT } from '../apollo/queries'
import { EMIT_ONLINE_MEMBER } from '../apollo/mutations'
import { ONLINE_MEMBER_SUBSCRIPTION } from '../apollo/subscriptions'

import './App.css'

function LeagueContent({ league, member, user, viewAllLeagues, signOut }) {
  const [state, setState] = useState({ navItem: 'overview' })
  const { navItem } = state

  const [emitMemberOnline] = useMutation(EMIT_ONLINE_MEMBER)
  const { loading, error, data } = useSubscription(ONLINE_MEMBER_SUBSCRIPTION, { variables: { id: league.id }})

  useEffect(() => {
    if (navItem === 'chat') {
      emitMemberOnline({ variables: { id: member.id, is_online: true, in_chat: true, in_video: false }})  
    } else if (navItem === 'video') {
      emitMemberOnline({ variables: { id: member.id, is_online: true, in_chat: false, in_video: true }})  
    } else {
      if (data) {
        const onlineMember = data.members.find(m => m.id === member.id)
        if (onlineMember.in_chat || onlineMember.in_video) {
          emitMemberOnline({ variables: { id: member.id, is_online: true, in_chat: false, in_video: false }})
        }
      }
    }
  }, [navItem, data])

  const count = useMemo(() => {
    let chat = 0
    let video = 0
    if (data) {
      chat = data.members.filter(m => m.in_chat).length
      video = data.members.filter(m => m.in_video).length
    }

    return { chat, video }
  }, [data])

  const menu = [
    { key: "overview", label: "League", icon: <img src={trophy} />, onClick: key => setState({ ...state, navItem: key }) },
    { key: "vote", label: "Vote", icon: <img src={vote} />, onClick: key => setState({ ...state, navItem: key }) },
    { key: "bet", label: "Challenge", icon: <img src={handshake} />, onClick: key => setState({ ...state, navItem: key }) },
    { key: "dues", label: "Dues", icon: <img src={mobilePay} />, onClick: key => setState({ ...state, navItem: key }) },
    { key: "event", label: "Events", icon: <img src={event} />, onClick: key => setState({ ...state, navItem: key }) },
    // { key: "chat", label: "Chat", icon: <img src={textChat} />, count: count.chat, onClick: key => setState({ ...state, navItem: key }) },
    // { key: "video", label: "Video", icon: <img src={videoChat} />, count: count.video, onClick: key => setState({ ...state, navItem: key }) }
  ]
  const submenu = [
    { key: "projects", label: "All Leagues", icon: <img src={back} />, onClick: viewAllLeagues },
    { key: "signOut", label: "Sign Out", icon: <img src={peace} />, onClick: signOut }
  ]

  return (
    <AppLayout menu={menu} submenu={submenu} defaultFirstMenuKey>
      {navItem === 'overview' && <Overview user={user} league={league} member={member} loading={loading} error={error} data={data} />}
      {navItem === 'vote' && <Votes league={league} user={user} member={member} />}
      {navItem === 'bet' && <Bets league={league} user={user} member={member} />}
      {navItem === 'dues' && <Dues league={league} user={user} member={member} />}
      {navItem === 'event' && <Events league={league} user={user} />}
      {/* {navItem === 'chat' && <Chat league={league} user={user} />}
      {navItem === 'video' && <VideoChat league={league} user={user}  />} */}
    </AppLayout>
  )
}

function League({ signOut, viewAllLeagues, selectedLeague, user }) {
  const { loading, error, data } = useQuery(GET_LEAGUE_DETAILS, { variables: { id: selectedLeague.id, user_id: user.id } })
 
  const [emitMemberOnline] = useMutation(EMIT_ONLINE_MEMBER)
  useEffect(() => {
    const member = selectedLeague.league_members.find(m => m.user_id === user.id)

    const emitMemberStatus = is_online => {
      emitMemberOnline({ variables: { id: member.id, is_online, in_chat: false, in_video: false }})  
    }

    const emitMemberOffline = () => {
      emitMemberOnline({ variables: { id: member.id, is_online: false, in_chat: false, in_video: false }})  
    }
  
    emitMemberStatus(true)
    window.addEventListener('beforeunload', emitMemberOffline)
    return () => {
      emitMemberStatus(false)
      window.removeEventListener('beforeunload', emitMemberOffline)
    }
  }, [])

  if (error || loading) return <AppLoading error={error} />
  return <LeagueContent league={data.league} user={user} member={data.member[0]} viewAllLeagues={viewAllLeagues} signOut={signOut} />
}

export default League