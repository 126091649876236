import React, { useState } from 'react'
import { HomeOutlined, DollarCircleOutlined, LogoutOutlined } from '@ant-design/icons'

import AppLayout from './AppLayout'

import LeagueList from './LeagueList'
import League from './League'
import Account from './Account'
import { UserContext } from './Auth'


import './App.css'
import leagues from '../images/leagues.svg'
import account from '../images/account.svg'
import peace from '../images/peace.svg'

function App({ signOut }) {
  const [navItem, setNavItem] = useState('leagues')
  const [selectedLeague, setSelectedLeague] = useState()

  const menu = [
    { key: "leagues", label: "Leagues", icon:  <img src={leagues} />, onClick: key => setNavItem(key) },
    { key: "account", label: "Account", icon:  <img src={account} />, onClick: key => setNavItem(key) },
    { key: "signOut", label: "Sign Out", icon: <img src={peace} />, onClick: signOut }
  ]  

  if (selectedLeague) return (
    <UserContext.Consumer>
      {({ user }) => (
        <League selectedLeague={selectedLeague} user={user} viewAllLeagues={() => setSelectedLeague()} signOut={signOut} />
      )}
    </UserContext.Consumer>
  )

  return (
    <UserContext.Consumer>
      {({ user }) => (
        <AppLayout menu={menu} defaultFirstMenuKey>
          {navItem === 'leagues' && <LeagueList user={user} onClick={league => setSelectedLeague(league)} />}
          {navItem === 'account' && <Account user={user} />}
        </AppLayout>
      )}
    </UserContext.Consumer>
  )
}

export default App