import React, { useState, useMemo } from 'react'
import { Modal, Avatar, Button, Row, Col, Card, Tag, Popconfirm, Tabs } from 'antd'
import { Form, Input } from 'formik-antd'
import { PlusOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'

import { Formik } from 'formik'
import { GET_LEAGUE_DETAILS } from '../apollo/queries'
import { ADD_DUE, UPDATE_DUE, REMOVE_DUE } from '../apollo/mutations'
import NoData from './NoData'

const Dues = ({ league, user, member }) => {
  const { dues, league_members } = league
  const [state, setState] = useState({ visible: false, payers: [], amount: '', value: '' })
  const [addDue] = useMutation(ADD_DUE, {
    update(cache, { data: { insert_due_one } }) {
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, dues: [insert_due_one, ...league.dues] } },
      })
    }
  })

  const [editDue] = useMutation(UPDATE_DUE, {
    update(cache, { data: { update_due_by_pk } }) {
      const newDues = league.dues.map(d => {
        if (d.id === update_due_by_pk.id) return update_due_by_pk
        return d
      })
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { id: league.id, user_id: user.id },
        data: { league: { ...league, dues: [...newDues] } },
      })
    }
  })


  const [deleteDue] = useMutation(REMOVE_DUE, {
    update(cache, { data: { delete_due_by_pk } }) {
      const newDues = league.dues.filter(i => i.id !== delete_due_by_pk.id)
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { id: league.id, user_id: user.id },
        data: { league: { ...league, dues: [...newDues] } },
      })
    }
  })

  const handleSubmit = async (values) => {
    if (state.payers.length > 0) {
      const payments = state.payers.map(async (id) => await addDue({ variables: { ...values, league_id: league.id, payer_id: id, payee_id: user.id }, refetchQueries: [{ query: GET_LEAGUE_DETAILS,  variables: {  id: league.id, user_id: user.id  } }] }))
      await Promise.all(payments)
      setState({ visible: false, payers: [],  amount: '', value: ''  })
    }
  }

  const handlePaid = id => {
    editDue({ variables: { id, paid: true }})
  }

  const handleCancel = () => {
    setState({ visible: false, payers: [], amount: '', value: '' })
  }

  const handleDelete = (id) => {
    deleteDue({ variables: { id } })
  }

  const addPayer = id => {
    if (state.payers.includes(id)) {
      const newPayers = state.payers.filter(p => p !== id)
      setState({ ...state, payers: [...newPayers]})
    } else {
      setState({ ...state, payers: [id, ...state.payers]})
    }
  }

  const Description = ({ due }) => {
    return (
      <div className="due-container">
        <div className="due-info">
          <div><Avatar size={128} src={due.payer.photoURL} /></div>
          <p>{due.payer.displayName}</p>
        </div>
        <div className="due-info">
          <div><Avatar size={128} src={due.payee.photoURL} /></div>
          <p>{due.payee.displayName}</p>
        </div>
        <div className="due-amount">
          <Tag color="green" className="due-tag">{due.amount}</Tag>
          <ArrowRightOutlined />
        </div>
      </div>
    )
  }

  const Footer = () => (
    <div className="add-button"><Button type="primary" size="large" shape="circle" onClick={() => setState({ visible: true, payers: [] })}><PlusOutlined /></Button></div>
  )

  const initialValues = { amount: state.amount, value: state.value }
  const canEdit = d => member.role === 'manager' || league.owner_id === user.id || d.payee.id === user.id

  const filteredDues = useMemo(() => {
    return { unpaid: dues.filter(d => !d.paid), paid: dues.filter(d => d.paid) }
  }, [dues])

  return (
    <div>
      <Tabs tabPosition="top">
        <Tabs.TabPane tab="Unpaid" key="unpaid">
          <Row gutter={[16, 16]}>
            {!filteredDues.unpaid.length && <NoData />}
            {filteredDues.unpaid.map(d => (
              <Col key={d.id} xs={24} xl={12}>
                <Card
                  title={
                  <>
                    <p>{d.value}</p>
                    {canEdit(d) && <div className="actions">
                      <Popconfirm
                          title="Are you sure?"
                          onConfirm={() => handlePaid(d.id)}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                        <a href="#">Paid</a>
                      </Popconfirm>
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => handleDelete(d.id)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                      <a href="#">Remove</a>
                    </Popconfirm>
                  </div>}
                  </>}>
                  <Description due={d} />
                </Card>
              </Col>   
            ))}
            </Row>
          <Footer />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Paid" key="paid">
          <Row gutter={[16, 16]}>
            {!filteredDues.paid.length && <NoData />}
            {filteredDues.paid.map(d => (
              <Col key={d.id} xs={24} xl={12}>
                <Card
                  title={
                  <>
                    <p>{d.value}</p>
                    {canEdit(d) && <div className="actions">
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => handleDelete(d.id)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                      <a href="#">Remove</a>
                    </Popconfirm>
                  </div>}
                  </>}>
                  <Description due={d} />
                </Card>
              </Col>   
            ))}
          </Row>          
        </Tabs.TabPane>
      </Tabs>
      <Formik initialValues={initialValues} enableReinitialize>
        {(props) => (
            <Modal
              title="Add Due"
              visible={state.visible}
              onOk={() => handleSubmit(props.values)}
              onCancel={handleCancel}
            >
              <Form>
                <Input style={{ margin: '10px 0' }} size="large" name="value" placeholder="Description" />
                <Input style={{ margin: '10px 0' }} size="large" name="amount" placeholder="Amount" />
                <div style={{ margin: '10px 0' }}>
                  <small>Payers</small>
                  {league_members.map(m => <div className={state.payers.includes(m.user.id) ? 'payer-avatar paying' : 'payer-avatar'} onClick={() => addPayer(m.user.id)}><Avatar src={m.user.photoURL} /></div>)}
                </div>
              </Form> 
            </Modal>
          )
        }
      </Formik>
    </div>
  )
}

export default Dues
