import React, { useState } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { Card, Form, Input, Button, Row, Col, Statistic } from 'antd'

import CardSection from './CardElements';

export default function CheckoutForm({ user, onSuccess }) {
  const [name, setName] = useState('')
  const stripe = useStripe()
  const elements = useElements()

  const handleCreate = () => {
    onSuccess(name)
    setName('')
  }

  const handleSubmit = async (event) => {
    if (!name) return
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(`{CLIENT_TOKEN}`, {
      lineItems: [{price: 'price_HMgOMWvkCDW1B2', quantity: 1}],
      mode: 'subscription',
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Alex Tideman',
        },
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message)
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        onSuccess(name)
        setName('')
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      <Input className="StripeElement" placeholder="League Name" value={name} onChange={e => setName(e.target.value)} />
      <Button disabled={!name || user.email !== 'alex.tideman@gmail.com'} type="primary" onClick={handleCreate}>
        Create League
      </Button>
    </form>
  );
}
