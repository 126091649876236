import { gql } from '@apollo/client'

export const GET_USER = gql`
  query GetUser($id: String!) {
    user: user_by_pk(id: $id) {
      id
      displayName
      email
      photoURL
    }
  }
`

export const GET_LEAGUES = gql`
  query GetUserLeagues($user_id: String!) {
    memberships: league_member(where: { user_id: { _eq: $user_id }, league: { is_deleted: { _eq: false }}}, order_by: {id: asc}) {
      id
      role
      user {
        id
        photoURL
        email
        displayName
      }
      league {
        id
        name
        slogan
        owner_id
        avatarURL
        league_members {
          id
          user_id
        }
      }
    }
  }
`

export const GET_LEAGUE_DETAILS = gql`
  query GetLeagueDetails($id: Int!, $user_id: String!) {
    league: league_by_pk(id: $id) {
      id
      name
      owner_id
      slogan
      chatroom
      password
      avatarURL
      league_members {
        id
        role 
        user {
          id
          photoURL
          email
          displayName
        }
      }
      ideas {
        id
        value
        approved
        passed
        poll
        options
        votes {
          id
          approve
          option
          user_id
          user {
            id
            photoURL
            email
            displayName
          }  
        }
        user {
          id
          photoURL
          email
          displayName
        }
      }
      bets {
        id
        value
        amount
        accepted
        rejected
        winner_id
        bettor {
          id
          photoURL
          email
          displayName
        }
        challenger {
          id
          photoURL
          email
          displayName
        }
      }
      dues {
        id
        value
        amount
        paid
        payee {
          id
          photoURL
          email
          displayName
        }
        payer {
          id
          photoURL
          email
          displayName
        }
      }
      events {
        id
        value
        location
        date
        time
        user_id
        attendees {
          id
          user_id
          event_id
          attending
          user {
            id
            photoURL
            email
            displayName
          }  
        }
        user {
          id
          photoURL
          email
          displayName
        }
      }
    }
    member: league_member(where: {league_id: {_eq: $id}, user_id: {_eq: $user_id}}) {
      id
      role
    }
    previous_message_count: unread_message(where: {league_id: {_eq: $id}, user_id: {_eq: $user_id}}) {
      id
      last_message_count
    }
  }
`

export const GET_MESSAGE_COUNT = gql`
  query GetLeagueMessageCount($id: Int!) {
    message_count: message_aggregate(where: {league_id: {_eq: $id }}) {
      aggregate {
        count
      }
    }
  }
`

export const SEARCH_USERS = gql`
  query SearchUsers($searchText: String!) {
    users: user(where: {_or: [{displayName: {_ilike: $searchText }}, {email: {_ilike: $searchText }}]}) {
      id
      displayName
      email
      photoURL
    }
  }
`

