import React, { useState, useMemo } from 'react'
import { Card, Button, Modal, Avatar, Popconfirm, Tag, Tabs, Popover, Slider } from "antd"
import { PlusOutlined } from '@ant-design/icons'
import { Form, Input } from 'formik-antd'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'

import thumbsUp from '../images/thumbs-up.svg'
import thumbsDown from '../images/thumbs-down.svg'

import NoData from './NoData'

import { GET_LEAGUE_DETAILS } from '../apollo/queries'
import { ADD_IDEA, ADD_VOTE, UPDATE_IDEA, UPDATE_VOTE, REMOVE_IDEA } from '../apollo/mutations'

const initialState = { visible: false, item: null, poll: false, numOptions: 3, options: ['','','','','','','',''] }
const Votes = ({ league, user, member }) => {
  const [state, setState] = useState(initialState)
  const [addIdea] = useMutation(ADD_IDEA, {
    update(cache, { data: { insert_idea_one } }) {
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, ideas: [...league.ideas, { ...insert_idea_one, votes: [] } ] } },
      })
    }
  })
  const [addVote] = useMutation(ADD_VOTE, {
    update(cache, { data: { insert_vote_one } }) {
      const newIdeas = league.ideas.map(i => {
        if (i.id === insert_vote_one.idea_id) {
          const newVotes = [...i.votes, insert_vote_one]
          return { ...i, votes: [...newVotes] }
        }
        return i
      })
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, ideas: [...newIdeas] } },
      })
    }
  })

  const [editIdea] = useMutation(UPDATE_IDEA, {
    update(cache, { data: { update_idea_by_pk } }) {
      const newIdeas = league.ideas.map(i => {
        if (i.id === update_idea_by_pk.id) return update_idea_by_pk
        return i
      })
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, ideas: [...newIdeas] } },
      })
    }
  })

  const [editVote] = useMutation(UPDATE_VOTE, {
    update(cache, { data: { update_vote_by_pk } }) {
      const newIdeas = league.ideas.map(i => {
        if (i.id === update_vote_by_pk.idea_id) {
          const newVotes = i.votes.map(v => {
            if (v.id === update_vote_by_pk.id) return update_vote_by_pk
            return v
          })
          return { ...i, votes: [...newVotes]}
        }
        return i
      })
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, ideas: [...newIdeas] } },
      })
    }
  })

  const [deleteIdea] = useMutation(REMOVE_IDEA, {
    update(cache, { data: { delete_idea_by_pk } }) {
      const newIdeas = league.ideas.filter(i => i.id !== delete_idea_by_pk.id)
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league: league.id },
        data: { league: { ...league, ideas: [...newIdeas] } },
      })
    }
  })

  const filteredIdeas = useMemo(() => {
    const open = league.ideas.filter(v => !v.passed)
    const passed = league.ideas.filter(v => v.passed)
    return { open, passed }  
  }, [league.ideas])

  const handleSubmit = (values) => {
    if (state.item) {
      editIdea({ variables: { ...values, id: state.item.id, passed: state.item.passed, poll: state.item.poll, options: state.item.options } })
    } else {
      addIdea({ variables: { ...values, league_id: league.id, user_id: user.id, poll: state.poll, options: state.options } })
    }
    setState({ ...state, visible: false, item: null })
  }

  const handlePass = idea => {
    editIdea({ variables: { id: idea.id, value: idea.value, passed: !idea.passed, poll: idea.poll, options: idea.options } })
  }

  const handleVote = (userVote, vote) => {
    if (Boolean(userVote)) {
      editVote({ variables: { id: userVote.id, approve: vote.approve } })
    } else {
      addVote({ variables: { ...vote } })
    }
  }

  const handleOptionVote = (userVote, vote) => {
    if (Boolean(userVote)) {
      editVote({ variables: { id: userVote.id, option: vote.option } })
    } else {
      addVote({ variables: { ...vote } })
    }
  }

  const handleCancel = () => {
    setState(initialState)
  }

  const handleDelete = (id) => {
    deleteIdea({ variables: { id } })
  }

  const Idea = ({ idea, open }) => {
    const canEdit = () => member.role === 'manager' || league.owner_id === user.id || idea.user.id === user.id
    const canPass = () => member.role === 'manager' || league.owner_id === user.id

    const userVote = idea.votes.find(v => v.user_id === user.id)
    const yeas = idea.votes.filter(a => a.approve)
    const nays = idea.votes.filter(a => !a.approve)
    const yeasContent = (<span className="yeas-content">
      {yeas.map(a => <Avatar key={a.user.photoURL} src={a.user.photoURL} />)}
    </span>)
    const naysContent = (<span className="yeas-content">
      {nays.map(a => <Avatar key={a.user.photoURL} src={a.user.photoURL} />)}
    </span>)

    return (
      <Card 
        className="league-card" 
        title={
          <div>
            <h3><Avatar size={48} src={idea.user.photoURL} />{idea.user.displayName}</h3>
            <div className="actions">
              {open && canEdit() &&
                <a href="#" onClick={() => setState({ ...state, visible: true, item: idea, numOptions: idea.options.filter(o => !!o).length, options: idea.options })}>Edit</a>
              }
              {canPass() && <Popconfirm
                title="Are you sure?"
                onConfirm={() => handlePass(idea)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">{!idea.passed ? "Close" : "Open"}</a>
              </Popconfirm>}
              {canEdit() && <Popconfirm
                title="Are you sure?"
                onConfirm={() => handleDelete(idea.id)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">Remove</a>
              </Popconfirm>}
            </div>
          </div>}>
        <p className="value-text">
          {idea.value}
        </p>
        {!idea.poll && <div className="voting">
          <p>
            <Button size="large" shape="circle" disabled={(userVote && userVote.approve) || !open} icon={<img src={thumbsUp} />} onClick={() => handleVote(userVote, { idea_id: idea.id, user_id: user.id, approve: true })} />
            <Button size="large" shape="circle" disabled={(userVote && !userVote.approve) || !open} icon={<img src={thumbsDown} />} onClick={() => handleVote(userVote, { idea_id: idea.id, user_id: user.id, approve: false })} />
            {/* <a onClick={() => setState({ visible: true, item: idea })}>Edit</a> */}
          </p>
          <p>
            <Popover trigger="click" className="yeas-popover" placement="bottom" content={yeasContent}><Tag className="yeas" color="green">{yeas.length} YEAS</Tag></Popover>
            <Popover trigger="click" className="nays-popover" placement="bottom" content={naysContent}><Tag className="nays" color="red">{nays.length} NAYS</Tag></Popover>
          </p>
        </div>}
        {idea.poll && <div className="options">
          {idea.options.filter(o => !!o).map((o, i) => <Button type={userVote && userVote.option === i + 1 ? "primary" : ""} className="option" block style={{ textAlign: 'center' }} onClick={() => open ? handleOptionVote(userVote, { idea_id: idea.id, user_id: user.id, option: i + 1 }) : {}} key={i}>{o} ({idea.votes.filter(v => v.option === i + 1).length})</Button>)}
        </div>}
      </Card>
    )
  }
  const Footer = () => (
    <div className="add-button"><Button type="primary" size="large" shape="circle" onClick={() => setState({ ...state, visible: true, item: null })}><PlusOutlined /></Button></div>
  )
  
  const initialValues = useMemo(() => {
    if (!state.visible) {
      return { value: '' }
    }

    if (state.item) {
      const { value } = state.item
      return { value }
    }

    return { value: '' }
  }, [state])

  const newForm = () => (
    <Form>
      <Button type={!state.poll ? "primary" : ""} style={{ margin: '10px 10px 10px 0' }} onClick={() => setState({ ...state, poll: false })}>Yea or Nay</Button>
      <Button type={state.poll ? "primary" : ""} style={{ margin: '10px 0' }} onClick={() => setState({ ...state, poll: true })}>Poll</Button>
      <Input.TextArea rows={3} style={{ margin: '10px 0' }} size="large" name="value" placeholder={state.poll ? "Poll" : "Proposal"} />
      {state.poll && <>
        <div style={{ textAlign: 'center'}}># of Options</div>
        <Slider value={state.numOptions} dots min={1} max={8} onChange={v => setState({ ...state, numOptions: v })} />
        {[...Array(state.numOptions).keys()].map(n => {
          return <Input key={n} style={{ margin: 10 }} value={state.options[n]} onChange={e => {
            const updated = [...state.options]
            updated[n] = e.target.value
            setState({ ...state, options: updated })
          }} />
        })}
      </>}
    </Form>
  )

  const editForm = () => (
    <Form>
      <Button type={!state.item.poll ? "primary" : ""} style={{ margin: '10px 10px 10px 0' }} onClick={() => setState({ ...state, item: { ...state.item, poll: false } })}>Yea or Nay</Button>
      <Button type={state.item.poll ? "primary" : ""} style={{ margin: '10px 0' }} onClick={() => setState({ ...state, item: { ...state.item, poll: true } })}>Poll</Button>
      <Input.TextArea rows={3} style={{ margin: '10px 0' }} size="large" name="value" placeholder={state.item.poll ? "Poll" : "Proposal"} />
      {state.item.poll && <>
        <div style={{ textAlign: 'center'}}># of Options</div>
        <Slider value={state.numOptions} dots min={1} max={8} onChange={v => {
          if (state.item.options[v]) {
            const updated = [...state.item.options]
            updated[v] = ''
            setState({ ...state, item: {...state.item, options: updated }, numOptions: v })
          } else {
            setState({ ...state, numOptions: v })
          }
        }} />
        {[...Array(state.numOptions).keys()].map(n => {
          return <Input key={n} style={{ margin: 10 }} value={state.item.options[n]} onChange={e => {
            const updated = [...state.item.options]
            updated[n] = e.target.value
            setState({ ...state, item: {...state.item, options: updated } })
          }} />
        })}
      </>}
    </Form>
  )

  return (
    <div>
      <Tabs tabPosition="top">
        <Tabs.TabPane tab="Open" key="open">
          {!filteredIdeas.open.length && <NoData />}
          {filteredIdeas.open.map(idea => <Idea key={idea.id} member={league.member} idea={idea} open />)}
          <Footer />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Closed" key="passed">
          {!filteredIdeas.passed.length && <NoData />}
          {filteredIdeas.passed.map(idea => <Idea key={idea.id} member={league.member} idea={idea} />)}
        </Tabs.TabPane>
      </Tabs>
      <Formik initialValues={initialValues} enableReinitialize>
        {(props) => (
            <Modal
              title={state.item ? state.item.poll ? "Edit Poll" : "Edit Proposal" : state.poll ? "Add Poll" : "Add Proposal"}
              visible={state.visible}
              onOk={() => handleSubmit(props.values)}
              onCancel={handleCancel}
            >
              {state.item ? editForm() : newForm()}
            </Modal>
          )
        }
      </Formik>
    </div>
  )
}

export default Votes