import React, { useState } from 'react'
import firebase from "firebase/app"
import "firebase/database"
import { Row, Col, Card, Avatar, Modal } from 'antd'
import { useMutation } from '@apollo/client'
import { Form, Input } from 'formik-antd'
import { Formik } from 'formik'
import FileUploader from "react-firebase-file-uploader"

import { GET_USER } from '../apollo/queries'
import { UPDATE_USER } from '../apollo/mutations'

function Account({ user }) {
  const [state, setState] = useState({ visible: false, isUploading: false, progress: 0, avatar: '', avatarURL: '' })

  const handleUploadStart = () => setState({ ...state, isUploading: true, progress: 0 })
  const handleProgress = progress => setState({ ...state, progress })
  const handleUploadError = error => {
    setState({ ...state, isUploading: false })
    console.error(error);
  }

  const handleUploadSuccess = filename => {
    setState({ ...state, avatar: filename, progress: 100, isUploading: false })

    firebase
      .storage()
      .ref("user-images")
      .child(filename)
      .getDownloadURL()
      .then(url => {
        setState({ ...state, avatarURL: url })
      })
  }

  const [editUser] = useMutation(UPDATE_USER, {
    update(cache, { data: { update_user_by_pk } }) {
      cache.writeQuery({
        query: GET_USER,
        variables: { id: user.id },
        data: { user: { ...update_user_by_pk } },
      })
    }
  })

  const handleSubmit = (values) => {
    editUser({ variables: { ...values, photoURL: state.avatarURL || user.photoURL, id: user.id } })
    setState({ visible: false, isUploading: false, progress: 0, avatar: '', avatarURL: '' })
  }

  const initialValues = { displayName: user.displayName, photoURL: user.photoURL }
  return (
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
        <Card title={user.displayName} extra={<a href="#" onClick={() => setState({ ...state, visible: true })}>Edit</a>}>
            <Card.Meta
              avatar={<Avatar size={64} src={user.photoURL} />}
              description={<div style={{ margin: '20px'}}>{user.email}</div>}
            />
          </Card>
          <Formik initialValues={initialValues} enableReinitialize>
            {(props) => (
                <Modal
                  title="Edit Account"
                  visible={state.visible}
                  onOk={() => handleSubmit(props.values)}
                  onCancel={() => setState({ ...state, visible: false })}
                >
                  <Form>
                    <Input style={{ margin: '20px 0' }} size="large" name="displayName" placeholder="Name" />
                    <label className="file-upload-button">Upload Avatar
                    {state.isUploading && <p>Progress: {state.progress}</p>}
                    <FileUploader
                      hidden
                      accept="image/*"
                      name="avatar"
                      randomizeFilename
                      storageRef={firebase.storage().ref("user-images")}
                      onUploadStart={handleUploadStart}
                      onUploadError={handleUploadError}
                      onUploadSuccess={handleUploadSuccess}
                      onProgress={handleProgress}
                    />
                    </label>
                    {state.avatarURL && <Avatar src={state.avatarURL} />}
                  </Form> 
                </Modal>
              )
            }
          </Formik>
        </Col>
      </Row>
    )
}

export default Account