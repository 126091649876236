
import React from 'react'
import trophy from '../images/trophy-black.svg'
import { Empty } from 'antd'

const NoData = ({ message = "Nothing here yet" }) => {
  return (
    <div className="empty-container">
    <Empty
    image={trophy}
    imageStyle={{
      height: 60,
    }}
    description={
      <span>
        {message}
      </span>
    }
    />
    </div>
  )
}

export default NoData