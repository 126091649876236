import React, { useState } from 'react'
import { Layout, Button, Row, Col, Tabs } from 'antd'
import { GoogleOutlined, ArrowRightOutlined } from '@ant-design/icons'

import { ChallengeCard, VoteCard, DueCard, EventCard, RuleCard } from './Card'
import vote from '../images/vote.svg'
import handshake from '../images/handshake.svg'
import event from '../images/event.svg'
import mobilePay from '../images/mobile-pay.svg'

import { useWindowSize } from '../hooks'
import logo from '../images/logo.png'

import './App.css'

const { Content, Footer } = Layout
const { TabPane } = Tabs;

const yeas = ['https://picsum.photos/id/237/200', 'https://picsum.photos/id/238/200', 'https://picsum.photos/id/239/200', 'https://picsum.photos/id/240/200', 'https://picsum.photos/id/241/200']
const nays = ['https://picsum.photos/id/242/200', 'https://picsum.photos/id/243/200']

const yeas2 = ['https://picsum.photos/id/237/200', 'https://picsum.photos/id/238/200', 'https://picsum.photos/id/239/200', 'https://picsum.photos/id/240/200', 'https://picsum.photos/id/241/200', 'https://picsum.photos/id/242/200']
const nays2 = ['https://picsum.photos/id/243/200']

function AppLogin({ authState, signIn }) {
  const { height } = useWindowSize()

  return (
    <Layout style={{ background: '#2b2b2b'}}>
      <Content style={{ margin: '24px 16px 0', background: '#ffffff' }}>
        <Row gutter={[32, 32]}>
            <Col xs={24} xl={24} >
              <div className="splash">
                <img alt="Logo" className="logo" src={logo} />
                <div className="splash-content">
                  <h3><i>Everything But The Score</i></h3>
                  <div><img alt="vote" className="icon" src={vote}/><img alt="challenge" className="icon" src={handshake}/><img alt="dues" className="icon" src={mobilePay}/><img alt="plan" className="icon" src={event}/></div>
                  <Button className="login-button" type="primary" size="large" onClick={signIn}>
                    <GoogleOutlined /> Login
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row tter={[32, 32]} className="splash-container">
            <Tabs defaultActiveKey="1" tabPosition='left' size="large">
              <TabPane tab="Vote" key="1">
                <Row gutter={[24, 24]}>
                  <Col xs={24} md={12}>
                    <div className="splash-description">
                      <h2><img alt="vote" src={vote}/> Vote</h2>
                      <p>The key to any good league is coming up with new ways to stay engaged and keep things fun for everyone. Voting in My League Life lets any member propose new ideas to add to the rulebook or just to have fun. You can propose a simple yea or nay idea, or a multiple-choice poll. Once the commissioner approves the idea for league voting, all members can vote on an idea.</p>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <VoteCard 
                      img="https://picsum.photos/id/237/200"
                      title="Bob Jones"
                      proposal="Lowest score of the week has to run a 5k wearing a T-Rex suit by the end of the month"
                      yeas={yeas}
                      nays={nays}
                    />
                  </Col>
                </Row>
            </TabPane>
              <TabPane tab="Challenge" key="2">
                <Row gutter={[24, 24]}>
                  <Col xs={24} md={12}>
                    <div className="splash-description">
                      <h2><img alt="challenge" src={handshake}/> Challenge</h2>
                      <p>There is a lot more fun to be had than checking the results of your weekly head-to-head matchup that most leagues use as a format. Get in on the side action, call out a member to duel in a challenge of your choosing and stakes.</p>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <ChallengeCard 
                      challenge="Highest WR point total this week"
                      stakes="Loser shovels winners driveway"
                      challengers={[{ name: 'Brett Hedlund', img: nays[0] }, { name: 'Dave Carb', img: yeas[0] }]}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Dues" key="3">
                <Row gutter={[24, 24]}>
                  <Col xs={24} md={12}>
                  <div className="splash-description">
                    <h2><img alt="dues" src={mobilePay}/> Dues</h2>
                    <p>Never again do you need to nag members on a group text about who has or hasn't paid up yet. Keep everyone accountable by tracking league dues and know exactly owes what to who.</p>
                  </div>
                  </Col>
                  <Col xs={24} md={12}>
                  <DueCard 
                    amount={100}
                    stakes="League dues"
                    payers={[{ name: 'Joel Gitar', img: nays[0] }, { name: 'Sam Shaw', img: yeas[1] }]}
                  />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Schedule" key="4">
                <Row gutter={[24, 24]}>
                  <Col xs={24} md={12}>
                    <div className="splash-description">
                      <h2><img alt="plan" src={event}/> Schedule</h2>
                      <p>Plan events and let the league know who will attend</p>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <EventCard 
                      img="https://picsum.photos/id/237/200"
                      title="Al Tideman"
                      event="BBQ at my place. I'll supply the meat and drinks, you bring the family and snacks."
                      location="545 48th St. Minneapolis, MN 55364"
                      date="July 20th, 2020  5:00 p.m."
                      yeas={yeas2}
                      nays={nays2}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Rulebook" key="5">
                  <Row gutter={[24, 24]}>
                    <Col xs={24} md={12}>
                      <div className="splash-description">
                        <h2><img alt="vote" src={vote}/> Rulebook</h2>
                        <p>The sacred constitution of your league - the final judgement on all decisions, awards, punishments, dues, and everything other weird querk that makes your league special. The commissioner is in charge of the  </p>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <RuleCard 
                        title="League Fees and Payout"
                        rules={[
                          "$100.00 is due from each player before draft of the upcoming fantasy season. Dues will be paid to the league Treasuer by methods of Venmo, cash, personal check or any other means deemed suitable by Treasuer.",
                          "Payout at the conclusion of the league is thus: $100 for Regular Season Champion, $100 for League Runner-up, $800 for League Champion."
                        ]}
                      />
                    </Col>
                  </Row>
              </TabPane>
            </Tabs>
          </Row>
      </Content>
      <Footer className="footer">Tide Web Services ©2020</Footer>
    </Layout>
  )
}

export default AppLogin