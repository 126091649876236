import React, { useState, useMemo } from 'react'
import dayjs from 'dayjs'
import { Card, Button, Modal, Avatar, Popconfirm, Tag, Tabs, Popover } from "antd"
import { PlusOutlined } from '@ant-design/icons'
import { Form, Input, DatePicker } from 'formik-antd'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'

import thumbsUp from '../images/thumbs-up.svg'
import thumbsDown from '../images/thumbs-down.svg'

import NoData from './NoData'

import { GET_LEAGUE_DETAILS } from '../apollo/queries'
import { ADD_EVENT, ADD_ATTENDEE, UPDATE_EVENT, UPDATE_ATTENDEE, REMOVE_EVENT } from '../apollo/mutations'

const Votes = ({ league, user }) => {
  const [state, setState] = useState({ visible: false, item: null })
  const [addEvent] = useMutation(ADD_EVENT, {
    update(cache, { data: { insert_event_one } }) {
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, events: [...league.events, { ...insert_event_one, attendees: [] } ] } },
      })
    }
  })

  const [addAttendee] = useMutation(ADD_ATTENDEE, {
    update(cache, { data: { insert_attendee_one } }) {
      const newEvents = league.events.map(e => {
        if (e.id === insert_attendee_one.event_id) {
          const newAttendees = [...e.attendees, insert_attendee_one]
          return { ...e, attendees: [...newAttendees] }
        }
        return e
      })
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, events: [...newEvents] } },
      })
    }
  })

  const [editEvent] = useMutation(UPDATE_EVENT, {
    update(cache, { data: { update_event_by_pk } }) {
      const newEvents = league.events.map(e => {
        if (e.id === update_event_by_pk.id) return update_event_by_pk
        return e
      })
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, events: [...newEvents] } },
      })
    }
  })

  const [editAttendee] = useMutation(UPDATE_ATTENDEE, {
    update(cache, { data: { update_attendee_by_pk } }) {
      const newEvents = league.events.map(e => {
        if (e.id === update_attendee_by_pk.event_id) {
          const newAttendees = e.attendees.map(a => {
            if (a.id === update_attendee_by_pk.id) return update_attendee_by_pk
            return a
          })
          return { ...e, attendees: [...newAttendees]}
        }
        return e
      })
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league_id: league.id },
        data: { league: { ...league, events: [...newEvents] } },
      })
    }
  })

  const [deleteEvent] = useMutation(REMOVE_EVENT, {
    update(cache, { data: { delete_event_by_pk } }) {
      const newEvents = league.events.filter(i => i.id !== delete_event_by_pk.id)
      cache.writeQuery({
        query: GET_LEAGUE_DETAILS,
        variables: { league: league.id },
        data: { league: { ...league, events: [...newEvents] } },
      })
    }
  })

  const filteredEvents = useMemo(() => {
    const upcoming = league.events.filter(e => new Date(e.date) > new Date())
    const past = league.events.filter(e => new Date(e.date) < new Date())
    return { upcoming, past }  
  }, [league.events])

  const handleSubmit = values => {
    if (state.item) {
      editEvent({ variables: { ...values, id: state.item.id } })
    } else {
      addEvent({ variables: { ...values, league_id: league.id, user_id: user.id } })
    }
    setState({ visible: false, item: null })
  }

  const handleAttending = (userAttendee, attendee) => {
    if (Boolean(userAttendee)) {
      editAttendee({ variables: { id: userAttendee.id, attending: attendee.attending } })
    } else {
      addAttendee({ variables: { ...attendee } })
    }
  }

  const handleCancel = () => {
    setState({ visible: false, item: null })
  }

  const handleDelete = (id) => {
    deleteEvent({ variables: { id } })
  }

  const Event = ({ member, event }) => {
    const userAttendee = event.attendees.find(a => a.user_id === user.id)
    const yeas = event.attendees.filter(a => a.attending)
    const nays = event.attendees.filter(a => !a.attending)
    const yeasContent = (<span className="yeas-content">
      {yeas.map(a => <Avatar key={a.user.photoURL} src={a.user.photoURL} />)}
    </span>)
    const naysContent = (<span className="yeas-content">
      {nays.map(a => <Avatar key={a.user.photoURL} src={a.user.photoURL} />)}
    </span>)

    return (
      <Card 
        className="league-card event-card"
        title={
          <div>
            <h3><Avatar size={48} src={event.user.photoURL} />{event.user.displayName}</h3>
            <div className="actions">
            {event.user.id === user.id && <a href="#" onClick={() => setState({ visible: true, item: event })}>Edit</a>}
            {event.user.id === user.id && <Popconfirm
              title="Are you sure?"
              onConfirm={() => handleDelete(event.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Remove</a>
            </Popconfirm>}
          </div>
          </div>}>
        <p className="value-text">
          {event.value}
        </p>
        <div className="voting">
          <p>
            <Button size="large" shape="circle" disabled={userAttendee && userAttendee.attending} icon={<img src={thumbsUp} />} onClick={() => handleAttending(userAttendee, { event_id: event.id, user_id: user.id, attending: true })} />
            <Button size="large" shape="circle" disabled={userAttendee && !userAttendee.attending} icon={<img src={thumbsDown} />} onClick={() => handleAttending(userAttendee, { event_id: event.id, user_id: user.id, attending: false })} />
          </p>
          <p>
            <Popover trigger="click" className="yeas-popover" placement="bottom" content={yeasContent}><Tag className="yeas" color="green">{yeas.length} YEAS</Tag></Popover>
            <Popover trigger="click" className="nays-popover" placement="bottom" content={naysContent}><Tag className="nays" color="red">{nays.length} NAYS</Tag></Popover>
          </p>
        </div>
        <p className="date-text">
          {dayjs(event.date).format('MMMM D, YYYY')}  {event.time}
        </p>
        <p className="location-text">
          <i>{event.location}</i>
        </p>
      </Card>
    )
  }
  const Footer = () => (
    <div className="add-button"><Button type="primary" size="large" shape="circle" onClick={() => setState({ visible: true, item: null })}><PlusOutlined /></Button></div>
  )
  
  const initialValues = useMemo(() => {
    if (!state.visible) {
      return { value: '', location: '', date: '', time: '' }
    }

    if (state.item) {
      const { value, location, date, time } = state.item
      return { value, location, date, time }
    }

    return { value: '', location: '', date: '', time: '' }
  }, [state])

  return (
    <div>
      <Tabs tabPosition="top">
        <Tabs.TabPane tab="Upcoming" key="upcoming">
          {!filteredEvents.upcoming.length && <NoData />}
          {filteredEvents.upcoming.map(event => <Event key={event.id} member={league.member} event={event} />)}
          <Footer />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Past" key="past">
          {!filteredEvents.past.length && <NoData />}
          {filteredEvents.past.map(event => <Event key={event.id} member={league.member} event={event} />)}
        </Tabs.TabPane>
      </Tabs>
      <Formik initialValues={initialValues} enableReinitialize>
        {(props) => (
            <Modal
              title={state.item ? "Edit Event" : "Add Event"}
              visible={state.visible}
              onOk={() => handleSubmit(props.values)}
              onCancel={handleCancel}
            >
              <Form>
                <Input.TextArea rows={5} style={{ margin: '10px 0' }} size="large" name="value" placeholder="Event Details" />
                <Input style={{ margin: '10px 0' }} size="large" name="location" placeholder="Location" />
                <DatePicker style={{ margin: '10px 0' }} size="large" name="date" placeholder="Date" />
                <Input style={{ margin: '10px 0' }} size="large" name="time" placeholder="Time" />
              </Form> 
            </Modal>
          )
        }
      </Formik>
    </div>
  )
}

export default Votes