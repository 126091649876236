import { gql } from '@apollo/client'

export const MESSAGES_SUBSCRIPTION = gql`
  subscription getMessages($league_id: Int!) {
    messages: message(where: {league_id: { _eq: $league_id }}, order_by: {created_at: asc}) {
      id
      value
      created_at
      user_id
      league_id
      user {
        displayName
        photoURL
      }
    }
  }
`;

export const ONLINE_MEMBER_SUBSCRIPTION = gql`
  subscription GetOnlineLeagueMembers($id: Int!) {
    members: league_member(where: { league_id: { _eq: $id } }, order_by: {user: { displayName: asc }}) {
      id
      role
      is_online
      in_chat
      in_video
      last_typed
      user {
        id
        photoURL
        email
        displayName
      }
    }
  }
`
