import React, { useState } from 'react'
import { Row, Col, Card } from 'antd'
import { useQuery } from '@apollo/client'

import { GET_LEAGUES } from '../apollo/queries'

import NewLeague from './NewLeague'
import trophy from '../images/trophy.svg'

function LeagueList({ onClick, user }) {
  const [createNew, setCreateNew] = useState(false)
  const { loading, error, data } = useQuery(GET_LEAGUES, { variables: { user_id: user.id } })
  
  if (loading || error) return null

  const onNewClick = () => {
    setCreateNew(true)
  }

  const onBack = () => {
    setCreateNew(false)
  }

  const onCreateComplete = () => {
    setCreateNew(false)
  }
 
  if (createNew) return <NewLeague user={user} onCreateComplete={onCreateComplete} onBack={onBack} />

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={8}>
        <Card className="new-league-card" onClick={onNewClick}>
          <h1>New League</h1>
          <p>Create a new league</p>
        </Card>
      </Col>
      {data.memberships.map((membership, i) => (
        <Col key={i} xs={24} lg={8}>
          <Card className="league-card" onClick={() => onClick(membership.league)} style={{ backgroundImage: `url("${membership.league.avatarURL || trophy}")`}}>
            <h1>{membership.league.name}</h1>
            <p>{membership.league.slogan}</p>
          </Card>
        </Col>
      ))}
      {!data.memberships.length &&
        <Col xs={24} lg={8}>
          <Card className="league-card" style={{ backgroundImage: `url("${trophy}")`, cursor: 'default'}}>
            <h1>Hang tight...</h1>
            <p>Your league owner will let you in shortly</p>
          </Card>
        </Col>}
    </Row>
  )
}

export default LeagueList