import React, { useState, useEffect, useMemo } from 'react'
import { Layout, Menu, Badge } from 'antd'

import { useWindowSize } from '../hooks'
import './App.css'

import logo from '../images/logo.png'

const { Content, Footer, Sider } = Layout

function AppLayout({ children, menu, submenu, disabled, defaultFirstMenuKey }) {
  const { height, width } = useWindowSize()
  return (
    <Layout>
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        className="ant-sider"
        style={{position: width < 768 ? "fixed" : "relative", height: width < 768 ? "100vh" : "inherit", zIndex: 9999 }}
      >
        <img className="logo" alt="Logo" src={logo} style={{ borderRadius: 0 }} />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={defaultFirstMenuKey && [menu[0].key]}>
          {menu.map(({ key, count = 0, label, className = '', icon, onClick }) => (
            <Menu.Item key={key} className={className} icon={<div className="logoContainer">{icon}</div>} disabled={disabled} onClick={() => onClick(key)} >
              {label}
              {count > 0 && <Badge status="success" title={count} style={{ margin: '0 5px' }} />}
            </Menu.Item>
          ))}
          {submenu && <hr />}
          {submenu && submenu.map(({ key, count = 0, label, className = '', icon, onClick }) => (
            <Menu.Item key={key} className={className} icon={<div className="logoContainer">{icon}</div>} disabled={disabled} onClick={() => onClick(key)} >
              {label}
              {count > 0 && <Badge status="success" title={count} style={{ margin: '0 5px' }} />}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ margin: '24px 16px 0'}}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: height }}>
            {children}
          </div>
        </Content>
        <Footer className="footer">Tide Web Services ©2020</Footer>
      </Layout>
    </Layout>
  )
}

export default AppLayout