import React, { useState } from 'react'
import { Row, Col, Button, Avatar, Card, Tag, Popover } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import './Card.css'

import thumbsUp from '../images/thumbs-up.svg'
import thumbsDown from '../images/thumbs-down.svg'

export const EventCard = ({ img, title, event, date, location, yeas, nays }) => {
  const yeasContent = (<span className="yeas-content">
    {yeas.map(a => <Avatar key={a} src={a} />)}
    </span>)
  const naysContent = (<span className="yeas-content">
    {nays.map(a => <Avatar key={a} src={a} />)}
    </span>)

  const Header = () => (
    <div className="league-card-header">
      <h3><Avatar size={42} src={img} />{title}</h3>
      <div className="actions" />
    </div>
  )

  const VoteActions = () => {
    return (
      <div className="right-actions">
        <p>
          <Button size="large" shape="circle" disabled icon={<img src={thumbsUp} />}  />
          <Button size="large" shape="circle" disabled icon={<img src={thumbsDown} />} />
        </p>
        <p>
          <Popover trigger="click" className="yeas-popover" placement="bottom" content={yeasContent}><Tag className="yeas" color="green">{yeas.length} YEAS</Tag></Popover>
          <Popover trigger="click" className="nays-popover" placement="bottom" content={naysContent}><Tag className="nays" color="red">{nays.length} NAYS</Tag></Popover>
        </p>
      </div>
    )
  }

  return (
      <Card 
        className="login-card league-card"
        title={<Header />}>
          <p className="card-text">{event}</p>
          <VoteActions />
          <p className="date-text">{date}</p>
          <p className="location-text"><i>{location}</i></p>
    </Card>
  )
}


export const DueCard = ({ amount, stakes, payers }) => {
  const Header = () => (
    <div className="league-card-header">
      <h3>{stakes}</h3>
    </div>
  )

  const payer = payers[0]
  const payee = payers[1]
  const ChallengeActions = () => (
    <div className="right-actions">
      <p>
        {payer?.name}
        <Avatar size={42} src={payer?.img} />
        <ArrowRightOutlined className="due-arrow" />
      </p>
      <p>
        {payee?.name}
        <Avatar size={42} src={payee?.img} />
      </p>
    </div>
  )

  return (
    <Card 
      className="login-card league-card"
      title={<Header />}>
        <p className="due-text">${amount.toFixed(2)}</p>
        <ChallengeActions />
    </Card>
  )
}

export const ChallengeCard = ({ challenge, stakes, challengers }) => {
  const Header = () => (
    <div className="league-card-header">
      <h3>{challenge}</h3>
    </div>
  )

  const challenger = challengers[0]
  const challengee = challengers[1]
  const ChallengeActions = () => (
    <div className="right-actions">
      <p>
        {challenger?.name}
        <Avatar size={42} src={challenger?.img} />
      </p>
      <p>
        {challengee?.name}
        <Avatar size={42} src={challengee?.img} />
      </p>
    </div>
  )

  return (
    <Card 
      className="login-card league-card"
      title={<Header />}>
        <p className="card-text">{stakes}</p>
        <ChallengeActions />
    </Card>
  )
}


export const VoteCard = ({ img, title, proposal, yeas, nays }) => {
  const yeasContent = (<span className="yeas-content">
    {yeas.map(a => <Avatar key={a} src={a} />)}
    </span>)
  const naysContent = (<span className="yeas-content">
    {nays.map(a => <Avatar key={a} src={a} />)}
    </span>)

  const Header = () => (
    <div className="league-card-header">
      <h3><Avatar size={42} src={img} />{title}</h3>
      <div className="actions" />
    </div>
  )

  const VoteActions = () => {
    return (
      <div className="right-actions">
        <p>
          <Button size="large" shape="circle" disabled icon={<img src={thumbsUp} />}  />
          <Button size="large" shape="circle" disabled icon={<img src={thumbsDown} />} />
        </p>
        <p>
          <Popover trigger="click" className="yeas-popover" placement="bottom" content={yeasContent}><Tag className="yeas" color="green">{yeas.length} YEAS</Tag></Popover>
          <Popover trigger="click" className="nays-popover" placement="bottom" content={naysContent}><Tag className="nays" color="red">{nays.length} NAYS</Tag></Popover>
        </p>
      </div>
    )
  }

  return (
      <Card 
        className="login-card league-card"
        title={<Header />}>
          <p className="card-text">{proposal}</p>
          <VoteActions />
    </Card>
  )
}

export const RuleCard = ({ title, rules }) => {

  const Header = () => (
    <div className="league-card-header">
      <h3>{title}</h3>
      <div className="actions" />
    </div>
  )


  return (
      <Card 
        className="login-card league-card"
        title={<Header />}>
          {rules.map(rule => <p className="card-text">{rule}</p>)}
    </Card>
  )
}
