import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useQuery, useMutation, useSubscription } from '@apollo/client'
import { Comment, Form, Button, List, Input, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import Loading from './Loading'
import { ADD_MESSAGE, REMOVE_MESSAGE, UPDATE_UNREAD_MESSAGE } from '../apollo/mutations'
import { MESSAGES_SUBSCRIPTION } from '../apollo/subscriptions'

const { TextArea } = Input

const MessageList = ({ league, user, handleDelete }) => {
  const { loading, error, data } = useSubscription(MESSAGES_SUBSCRIPTION, { variables: { league_id: league.id }})

  if (loading || error) return <Loading error={error} />

  const messages = data.messages.map(m => {
    return {
      id: m.id,
      user_id: m.user_id,
      author: m.user.displayName,
      avatar: m.user.photoURL,
      content: <p>{m.value}</p>,
      datetime: dayjs(m.created_at).format('MMMM D, YYYY h:mm:ss a'),
    }
  })

  return (
    <List
      dataSource={messages}
      header={`${messages.length} ${messages.length > 1 ? 'replies' : 'reply'}`}
      itemLayout="horizontal"
      renderItem={props => {
        return (
          <div>
            {props.user_id === user.id && <Button 
              danger 
              shape="circle" 
              size="small" 
              className="message-delete" 
              onClick={() => handleDelete(props.id)}>
                <DeleteOutlined />
            </Button>}
            <Comment {...props} />
          </div>
        )}}
    />
  )
}

const Editor = ({ onChange, onSubmit, onPressEnter, submitting, value }) => {
  return (
    <div className="chat-editor">
      <Form.Item>
        <TextArea rows={3} onChange={onChange} onPressEnter={onPressEnter} value={value} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
          Add Comment
        </Button>
      </Form.Item>
    </div>
  )
}

const Chat = ({ league, user, previous_message_count }) => {
  const [state, setState] = useState({ value: '', submitting: false })
  const { value, submitting } = state
  const { loading, error, data } = useSubscription(MESSAGES_SUBSCRIPTION, { variables: { league_id: league.id }})

  const [addMessage] = useMutation(ADD_MESSAGE)
  const [removeMessage] = useMutation(REMOVE_MESSAGE)

  // const [updateUnreadMessage] = useMutation(UPDATE_UNREAD_MESSAGE, {
  //   update(cache, { data: { update_unread_message } }) {
  //     cache.writeQuery({
  //       query: GET_LEAGUE_DETAILS,
  //       variables: { id: league.id, userId: user.id },
  //       data: { league: { ...league }, previous_message_count: update_unread_message.returning },
  //     })
  //   }
  // })

  // useEffect(() => {
  //   return () => {
  //     if (data && previous_message_count && data.messages && data.messages.length !== previous_message_count[0].last_message_count) {
  //       updateUnreadMessage({ variables: { league_id: league.id, user_id: user.id, last_message_count: data.messages.length }})  
  //     }  
  //   }
  // }, [data, previous_message_count])

  const handleSubmit = async (e) => {
    if (!value) return

    if (e) {
      e.preventDefault()
    }
  
    setState({ ...state, submitting: true })
    addMessage({ variables: { value, league_id: league.id, user_id: user.id } })
    setState({ value: '', submitting: false })
  }

  const handleChange = e => {
    setState({ ...state, value: e.target.value })
  }

  const handleDelete = id => {
    removeMessage({ variables: { id }})
  }

  return (
    <div className="chat">
      <MessageList
        league={league}
        user={user}
        handleDelete={handleDelete} />
      <Comment
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            onPressEnter={handleSubmit}
            submitting={submitting}
            value={value}
            user={user}
          />
        }
      />
    </div>
  )
}

export default Chat
