import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Button, Row, Col, Statistic } from 'antd'
import { useMutation } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import CheckoutForm from './CheckoutForm'

import { GET_LEAGUES } from '../apollo/queries'
import { ADD_LEAGUE, ADD_LEAGUE_MEMBER } from '../apollo/mutations'

const stripePromise = loadStripe("pk_test_YdYhRX5rz5p1tnYe9I3Iy7vJ00dLOHJD7W");

function NewLeague({ user, onCreateComplete, onBack }) {
  const [league, setLeague] = useState()
  const [addLeague] = useMutation(ADD_LEAGUE, {
    update(cache, { data: { insert_league_one } }) {
      setLeague(insert_league_one)
    }
  })

  const [addLeagueMember] = useMutation(ADD_LEAGUE_MEMBER, {
    update(cache, { data: { insert_league_member_one } }) {
      const { memberships } = cache.readQuery({ query: GET_LEAGUES, variables: { user_id: user.id } })
      const newLeague = { ...league, league_members: [{ id: insert_league_member_one.id, user_id: insert_league_member_one.user.id }] }
      const newMembership = { ...insert_league_member_one, league: newLeague }
      cache.writeQuery({
        query: GET_LEAGUES,
        variables: { user_id: user.id },
        data: { memberships: [newMembership, ...memberships] },
      })
      setLeague()
      onCreateComplete()
    }
  })

  useEffect(() => {
    if (league) {
      addLeagueMember({ variables: { user_id: user.id, league_id: league.id, role: "member" } })
    }
  }, [league])

  const handleCreate = name => {
    addLeague({ variables: { name, owner_id: user.id, chatroom: name } })
  }

  return (
    <Card title="Create New League" extra={<Button onClick={onBack}>Back</Button>}>
      <div style={{ margin: '5%' }}>
        <p>When you create a league, you become the owner. That means you have full control of who joins the league and what access they have. From there, you can start voting on ideas, challenge each other to friendly wagers, keep track of league dues, plan events and talk trash.</p>
        <Row gutter={16}>
          <Col xs={24} md={12} style={{ textAlign: 'center'}}>
            <Statistic title="To Join a League" value="Free" />
          </Col>
          <Col xs={24} md={12} style={{ textAlign: 'center'}}>
            <Statistic title="To Create A League" value="$5.99" />
            <Elements stripe={stripePromise}>
              <CheckoutForm user={user} onSuccess={handleCreate} />
            </Elements>
          </Col>
        </Row>
      </div>
    </Card>
  )
}

export default NewLeague